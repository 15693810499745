.header{
    /* border: 2px solid red; */
    /* height: 80vh; */
    background-image: url(../Header/toyota.jpeg);
    background-repeat: no-repeat;
    background-position:top;
    /* padding: 3rem 0; */
    margin-top: 6rem;
    
}
.header-container{
    
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 100%;     */
}
.header-container h1{
    margin-top: 38rem;
    font-size: 7rem;
    margin-bottom: 2rem;
    /* border: 2px solid red; */
}
.header-container h2 {
    font-size: 1.8rem;
    /* border: 2px solid red; */
    text-align: center;
    width: 60%;
    font-style: italic;
    margin-bottom: 5rem;
}

/* ************ Media Queries*************** */
@media (max-width:990px){
    .header-container h1{
        margin-top: 35rem;
        font-size: 4rem;        
    }
}
@media (max-width:890px) {
    .header-container h1{
        margin-top: 35rem;
        font-size: 4rem;        
    }
    .header-container h2{
        font-size: 1.5rem;
    }
}

@media (max-width:590px) {
    .header-container h1{
        margin-top: 23rem;
        font-size: 4rem;        
    }
    .header-container h2{
        font-size: 1.5rem;
    }
    .header{
        background-size: 80vmax;
    }
}
@media (max-width:490px){
    .header-container h1{
        margin-top: 23rem;
        font-size: 3rem;        
    }
    
    .header{
        background-size: 50vmax;
    }
}
@media (max-width:395px){
    .header-container h1{
        margin-top: 15rem;
        font-size: 2rem;        
    }

  
}
    
    