.demo{
    /* border: 2px solid black; */
    padding: 3rem 0;
}
.demo-container{
    /* border: 2px solid blue; */
    display: flex;
    flex-wrap: wrap;
}
.demo-image{
/* border: 2px solid orange; */
padding: 4rem;
width: 1100px;
height: 500px;
flex: 1 1 600px;
}
.demo-image img{
/* border: 2px solid green; */
width: 100%;
height: 100%;
object-fit: cover;
object-position:right;
box-shadow: 10px 5px 5px rgba(128, 128, 128, 0.342) ;
border-radius: 1rem;
}
.demo-text{
    flex: 1 1 400px;
    /* border: 2px solid black; */
    
}


.imageHeading{
    /* border: 2px solid red; */
   background-image: url(../Demo/textIcon.png);
   background-position: left;
   background-size:7vmax;
   background-repeat: no-repeat;
   
   height: 20vh;
   width: 50%;     
   margin-bottom: 2rem; 
   position: relative; 
}

.imageHeading h1{
   position: absolute;
    font-size: 3rem;
    /* margin-top:7rem ;
    margin-left: 10%; */
    top: 40%;
    left: 10%;

}
.demo-text p{
    /* border: 2px solid black; */
    font-size: 2rem;
    line-height: 3rem;
    width: 50%;
    margin-left: 10%;
    margin-bottom: 3rem;
}
.btn-demo{
    margin-left: 10%;
    font-size: 1.5rem;
    border:none;
    background-color: black;  
    padding: 1rem;

}
.btn-demo a {
    color: white;
    text-decoration: none;

}

/* ************** Media Queries ************ */
/* @media (max-width : 1050px) {

} */

@media (max-width : 1050px) {
    
    .demo-image{
    
    padding: 0rem;
    width: 400px;
    height: 500px;
    flex: 1 1 400px;
    }    
    .demo-text{
        flex: 1 1 200px;       
        
    }

    .imageHeading{
       
       width: 100%;           
        
    }

    .imageHeading h1{
        font-size: 3rem;

       
    }
    .demo-text p{
    
        font-size: 1.5rem;
        line-height: 2.5rem;
        width: 90%;
        margin-left: 10%;
        margin-bottom: 3rem;
        padding-right: 3rem;
        margin-top: 5rem;
    }
   
    
}

@media (max-width : 900px){
    .imageHeading h1{
        font-size: 3rem;  
        /* color: green; */
          
    }
    

    .demo-text p{
    
        margin-top: 5rem;
    
    }
   
}


@media (max-width : 790px){

}
@media (max-width : 690px){
    .demo-text p {
        /* margin-top: 5rem; */
        font-size: 1.3rem;

    }
    .imageHeading h1 {
        
        /* border: 2px solid green; */
        font-size: 2rem;
    }

}

/* All the devices below 550 */

@media (max-width : 550px) {
    .demo-image{
        /* border: 2px solid orange; */
        padding: 0rem;
        /* width: 300px;
        height: 400px; */
        width: 250px;
        height: 250px;
        flex: 1 1 400px;
    }
    
        
        .imageHeading h1{
            /* border: 2px solid green; */
         font-size: 3rem;       
         width: 90%;
     }
     .demo-text p{
     padding-right: 3rem;
     /* border: 2px solid red; */
     margin-top: 1rem;
     
    }
}

@media (max-width : 395px){
        .imageHeading h1 {
            font-size: 2.5rem;
            color: black;
        }
}