.demo8{
    /* border: 2px solid red; */
}

.demo8-container{
    /* border: 2px solid green; */
    /* display: flex;
    flex-wrap: wrap; */
    /* justify-content: center; */
    
}

 .demo8-cars{
 /* border: 2px solid blue; */
 display: flex;
 flex-wrap: wrap;
} 

.cars{
    flex: 1 1 400px;
    /* width: 390px;
    height: 400px; */
    /* border: 2px solid orange; */
}
.cars img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}