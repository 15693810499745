.demo7{
    /* border: 2px solid black; */
    padding: 5rem 0;
    background-image: linear-gradient(to right, #92fe9d 0%, #00c9ff 100%);
}
/* .demo-container6{
 
    display: flex;
    flex-wrap: wrap;
} */
/* .demo-image6{

width: 1100px;
height: 500px;
flex: 1 1 600px;
margin-right: 4rem;
}
.demo-image6 img{

width: 100%;
height: 100%;
object-fit: cover;
object-position:right;

} */
.demo-text7{
    /* flex: 1 1 400px; */
    /* border: 2px solid black; */
    margin: auto;
    /* max-width: 400px; */
    /* width: 400px; */
    margin-left: 0;
    
    
}


.imageHeading7{
   
   background-image: url(../Demo/textIcon.png);
   background-position: left;
   background-size:7vmax;
   background-repeat: no-repeat;
   
   height: 20vh;
   width: 50%;     
   margin-bottom: 2rem; 
   position: relative; 
}

.imageHeading7 h1{
   position: absolute;
    font-size: 3rem;
    /* margin-top:7rem ;
    margin-left: 10%; */
    top: 40%;
    left: 10%;

}
.demo-text7 p{
    /* border: 2px solid black; */
    font-size: 2rem;
    line-height: 3rem;
    width: 50%;
    margin-left: 10%;
    margin-bottom: 3rem;
}
.btn-demo7{
    margin-left: 10%;
    font-size: 1.5rem;
    border:none;
    background-image: linear-gradient(to top, #9795f0 0%, #fbc8d4 100%);
    padding: 1rem 2rem;
    text-transform: uppercase;
    border-radius: 2rem;

}
.btn-demo7 a {
    color: white;
    text-decoration: none;

}

/* ************** Media Queries ************ */
/* @media (max-width : 1050px) {

} */

@media (max-width : 1050px) {
    
    /* .demo-image6{
    
    padding: 0rem;
    width: 400px;
    height: 500px;
    flex: 1 1 400px;
    }     */
    .demo-text7{
        flex: 1 1 200px;       
        
    }

    .imageHeading7{
       
       width: 100%;           
        
    }

    .imageHeading7 h1{
        font-size: 3rem;

       
    }
    .demo-text7 p{
    
        font-size: 1.5rem;
        line-height: 2.5rem;
        width: 90%;
        margin-left: 10%;
        margin-bottom: 3rem;
        padding-right: 3rem;
        margin-top: 5rem;
    }
   
    
}

@media (max-width : 900px){
    .imageHeading7 h1{
        font-size: 3rem;  
        /* color: green; */
          
    }
    

    .demo-text7 p{
    
        margin-top: 5rem;
    
    }
   
}


@media (max-width : 790px){

}
@media (max-width : 690px){
    .demo-text7 p {
        /* margin-top: 5rem; */
        font-size: 1.3rem;

    }
    .imageHeading7 h1 {
        
        /* border: 2px solid green; */
        font-size: 2rem;
    }

}

/* All the devices below 550 */

@media (max-width : 550px) {
    .demo-image7{
        /* border: 2px solid orange; */
        padding: 0rem;
        /* width: 300px;
        height: 400px; */
        width: 250px;
        height: 250px;
        flex: 1 1 400px;
    }
    
        
        .imageHeading7 h1{
            /* border: 2px solid green; */
         font-size: 3rem;       
         width: 90%;
     }
     .demo-text7 p{
     padding-right: 3rem;
     /* border: 2px solid red; */
     margin-top: 1rem;
     
    }
}

@media (max-width : 395px){
        .imageHeading7 h1 {
            font-size: 2.5rem;
            color: black;
        }
}