
.menubar-desktop{
    /* border: 2px solid black; */
    /* border-bottom: 1px solid black; */
    
}
.desktop-container{
    padding: 3rem 0;
    /* border: 2px solid blue; */
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.menu-logo{
width: 75px;
height: 50px;
}
.menu-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
}
.desktopbar{
    /* border: 2px solid green; */
    
}
.desktopbar ul {
    list-style: none;
    display: flex;
    /* z-index: 999; */
    
    
}
.desktopbar li {
    
}
.desktopbar a {
    padding: 1rem;    
    text-decoration: none;
    color: black;
    font-size: 2rem;
    /* border: 2px solid orange; */
    margin: 0 1rem;
    
}
.desktopbar a:hover{
    background-color: purple;
    color:  white;
    transition: 0.5s;
}

/* *************** Mobile Menu************ */
.menubar-mobile{
    display: none;
    /* border: 2px solid red; */
}
.ham-container{
    font-size: 40px;
    display: flex;
    justify-content: flex-end;
}
.mobile-links{
    width: 1200px;
    /* border: 2px solid green; */
    position: absolute;    
    background-color: black;
    z-index: 999; 
    
    
    
}
.mobile-links ul {
    list-style: none;
    /* display: flex;
    flex-direction: column; */
    line-height: 3rem;
    font-size: 1.5rem;
    text-align: center;
   
    
    
}
.mobile-links li {
padding: 1rem 0;
/* border: 2px solid red; */

}
.mobile-links a{
    text-decoration: none;
    /* border: 2px solid blue; */
    padding: 1rem;
    color: white;
}
.mobile-links a:hover{
    
    color: green;
}

/* ********* Media Queries********** */


@media (max-width:990px){
    .menubar-desktop ul{
        display: none;
    }
    .desktop-container {
        justify-content: center;
    }
    .menubar-mobile{
        display: flex;
        /* width: 990px; */
    }
    .mobile-links {
        width:90%; 
    }
}