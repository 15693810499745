.demo5{
    /* border: 2px solid red; */
    padding: 10rem 0;
    background-image: linear-gradient(to top, #9890e3 0%, #b1f4cf 100%);;
}
.demo5-container{
    /* border: 2px solid blue; */
    display: flex;
    /* flex-wrap: wrap; */

}
.demo5-text1{
/* border: 2px solid orange; */
flex: 1 1 600px;
margin: auto;
margin-left: 25rem;
}
.demo5-text1 p {

    font-size: 2rem;
    padding: 3rem;
    /* border: 2px solid black; */
    width: 60%;
    /* display: block; */
    margin: auto;
    color: white;
    
}
.demo5-text2{
/* border: 2px solid green; */
flex: 1 1 600px;
margin: auto;
/* margin-left: 25rem; */
padding: 6rem;
background-image: linear-gradient(to top, #9890e3 0%, #b1f4cf 100%);;
border-radius: 20rem;
/* margin-left: 20rem; */
}
.demo5-text2 h1 {
    font-size: 5rem;
    margin-bottom: 5rem;
    margin-left: 25rem;
    /* border: 2px solid red; */
    width: 55%;
}
.btn-demo5{
    font-size: 1.5rem;
    border:none;
    background-color: black;  
    padding: 1rem;   
    margin-left: 25rem;
}
.btn-demo5 a {
    text-decoration: none;
    color: white;
}

   /* ********Media Queries*********** */

@media (max-width : 1300px) {
    .demo5-text1{
        
        margin-left: 0rem;
        margin-right: 2rem;
        }

        .demo5-text1 p {

            font-size: 1.8rem;            
            width: 100%;          
            
        }

        .demo5-text2{
            
            
            border-radius: 20rem;
            
            }
            .demo5-text2 h1 {
                font-size: 4rem;
                margin-left: 0rem;
                width: 100%;
            }
                
                
            .btn-demo5{                   
                margin-left: 0rem;
            }
}
@media (max-width : 900px){
    .demo5-text2 h1 {
        font-size: 3rem;
    }
}

@media (max-width : 690px){
    
    .demo5-text1 p {

        font-size: 1.5rem;            
        width: 100%;          
        
    }
    .demo5-text2 h1 {
        font-size: 2.8rem;
    }
}

@media (max-width : 550px){

    .demo5-container{
     
        flex-wrap: wrap;
    
    }

    .demo5-text1 p {

        font-size: 1.3rem;            
        width: 100%;          
        
    }
    .demo5-text2{
        margin-top: 3rem;
    }
    .demo5-text2 h1 {
        font-size: 2rem;
    }
}

